import React, { useState } from 'react';
import supabase from './supabaseClient';
import logoImage from './images/logo.svg';


function App() {
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  const handleSearch = async () => {
    try {
      const { data, error } = await supabase
        .from('domains')
        .select('url')
        .eq('name', name)
        .single();

      if (error) {
        alert('Name not found!');
        console.error(error);
        return;
      }

      if (data && data.url) {
        setUrl(data.url);
        window.location.href = data.url;
      }
    } catch (err) {
      console.error('Error fetching domain:', err);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      {/* Square Container */}
      <div
        style={{
          display: 'flex',
          width: '400px',  // Set a fixed size for the square
          height: '400px', // Fixed height and width to make it square
          borderRadius: '15px',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
        }}
      >
        {/* Left Section: Form */}
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            borderRight: '1px solid #ccc',
          }}
        >
          <h1 style={{ fontSize: '16px', marginBottom: '20px' }}>Dominio da sua organização</h1>
          <input
            type="text"
            placeholder="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              padding: '10px',
              fontSize: '16px',
              marginBottom: '20px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              width: '100%',
            }}
          />
          <button
            onClick={handleSearch}
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              borderRadius: '5px',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            Go
          </button>
        </div>

        {/* Right Section: Image */}
        <div
          style={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#e6e6e6',
            padding: '10px',
          }}
        >
            <img
            src={logoImage} // Use the imported image variable
            alt="Logo"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
